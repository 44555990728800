import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"border":"1px solid #ccc"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_Editor = _resolveComponent("Editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Toolbar, {
      style: {"border-bottom":"1px solid #ccc"},
      editor: _ctx.editorRef,
      defaultConfig: _ctx.toolbarConfig,
      mode: _ctx.mode
    }, null, 8, ["editor", "defaultConfig", "mode"]),
    _createVNode(_component_Editor, {
      style: {"height":"500px","overflow-y":"hidden"},
      modelValue: _ctx.valueHtml,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueHtml) = $event)),
      defaultConfig: _ctx.editorConfig,
      mode: _ctx.mode,
      onOnChange: _ctx.handleChange,
      onOnCreated: _ctx.handleCreated
    }, null, 8, ["modelValue", "defaultConfig", "mode", "onOnChange", "onOnCreated"])
  ]))
}